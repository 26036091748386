/* eslint-disable default-case */
import * as React from 'react';
import { createRoot } from 'react-dom/client'
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport,  CommandColumn, Filter, Inject, Sort, InfiniteScroll, Virtualization } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import DettaglioIncarico from './DettaglioIncarico';
import { SampleBase } from './sampleBase';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import { random, isUndefined } from 'underscore';
import moment from 'moment';
import ElencoComuni from '../assets/ListaComuni.json';

let GridData = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.4vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'IdIncarico' ? '#133B95' : 'purple';
    let renderDiv = <div><span style={{ fontSize: '1.6vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;

    return (
        renderDiv
    );
}
export default class SituazioneIncarichi extends SampleBase {
    constructor() {
        super(...arguments);               
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.editSettings = { allowAdding: true, mode: 'Normal'};
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStati = _.first(JSON.parse(sessionStorage.getItem('JellyfishTechMobileTabellaSTP')).filter(elm => { return !CustomJS.isNullEmpty(elm.VALUE) }), 3);
        this.toolbarOptions = ['Add', '   ', { template: this.ddlStatiToolbar.bind(this) }, '   ', 
                               {text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search'];        
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Vedi Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" }},
                         { type: "Duplica Incarico", buttonOption: { iconCss: "fas fa-clone fa-10x", cssClass: "e-details" }},
                         { type: "Invio Incarico in Sede", buttonOption: { iconCss: "fas fa-paper-plane fa-10x", cssClass: "e-details" }},
                         { type: "Archivia Incarico", buttonOption: { iconCss: "fas fa-archive fa-10x", cssClass: "e-details" }}];
        this.dialogButtons1 = [
            {
                click: () => {
                    this.dialogInstance1.hide();  
                    this.invioIncaricoSede(true);                           
                },
                buttonModel: {
                    content: 'CONFERMA INVIO',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance1.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ]; 
        this.dialogButtons2 = [
            {
                click: () => {
                    this.dialogInstance2.hide();  
                    this.duplicaIncarico(this);                           
                },
                buttonModel: {
                    content: 'CONFERMA DUPLICAZIONE',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance2.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogButtons3 = [
            {
                click: () => {
                    this.dialogInstance3.hide();  
                    this.archiviaIncarico(this);                           
                },
                buttonModel: {
                    content: 'CONFERMA ARCHIVIAZIONE',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance3.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogButtons4 = [
            {
                click: () => {
                    this.dialogInstance4.hide();  
                    this.archiviaMultiplaIncarico(this);                           
                },
                buttonModel: {
                    content: 'CONFERMA ARCHIVIAZIONE MULTIPLA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance4.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogButtons5 = [{
            click: () => {
                this.dialogInstance4.hide();
            },
            buttonModel: {
                content: 'OK',
                cssClass: 'e-primary',
                isPrimary: true
            }
        }];  
        this.dialogButtons6 = [
            {
                click: () => {
                    this.dialogInstance4.hide();  
                    this.invioIncaricoSede(true);                           
                },
                buttonModel: {
                    content: 'REINVIA INCARICO COMPLETO',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance4.hide();  
                    this.invioIncaricoSede(false);                           
                },
                buttonModel: {
                    content: 'REINVIA SOLO DOCUMENTAZIONE',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance4.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.salvaRigaSel = {};
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.childKey = 0;
    }
    state = {
        blocking: false,
        showDialog: false 
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    onQueryCellInfo(args) {
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 4; ind++)
            document.getElementById('GridSituazioneIncarichi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        setTimeout(() => {
            this.gridInstance.toolbarModule.enableItems(['GridSituazioneIncarichi_add'], false);
            $('span.e-label').hide();  //TODO : trovare soluzione più pulita a livello di codice oppure di CSS
        },10);
    }
    onRowDatabound(args) {
        debugger;
        if (args.data.Stato === 'DAINV') {
            args.row.className = 'e-row';
            args.row.bgColor = 'yellow';  
        }     
        $($(args.row).find('button')[1]).hide(); 
        $($(args.row).find('button')[2]).hide(); 
        $($(args.row).find('button')[4]).hide();
        if (args.data.Stato === 'INV') {    
            $($(args.row).find('button')[0]).hide(); 
            $($(args.row).find('button')[1]).show();
            $($(args.row).find('button')[2]).show();
            $($(args.row).find('button')[4]).show();
        }
        if (args.data.PresenzaDocs === 0) 
            $($(args.row).find('button')[3]).hide();                        
        else
            $($(args.row).find('button')[3]).show(); 
        if (args.data.Stato === 'ARCH') {  
            args.row.className = 'e-row';
            args.row.bgColor = '#f5a442';  
            console.log($($(args.row).find('button')[2]));
            $($(args.row).find('button')[0]).hide(); 
            $($(args.row).find('button')[1]).hide();
            $($(args.row).find('button')[2]).show();
            $($(args.row).find('button')[3]).hide();
        }        
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'GridSituazioneIncarichi_add') {
            args.cancel = true;            
            sessionStorage.setItem('JellyfishTechMobileDatiIncarico', JSON.stringify({ GuidIncarico: 'NEW', NotaIncarico: null, Nazione: 'ITA', Stato: 'DAINV', PresenzaDocs: 0 }));
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container); 
            root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>);  
        }
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-edit') > -1 || args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            sessionStorage.setItem('JellyfishTechMobileDatiIncarico', JSON.stringify(args.rowData));            
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container); 
            root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>);  
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-paper-plane') > -1) {
            if (CustomJS.isNullEmpty(args.rowData.DataSopralluogo) && this.ddlInstance.value === 'DAINV') {
                this.dialogInstance4.content = `Invio incarico in sede non effettuabile in quanto non è stata impostata la <span style='font-size:15px;font-weight:bolder'>Data Effettivo Sopralluogo</span>. Entrare nel dettaglio incarico ed impostarla prima di inviare l'incarico in sede...`;
                this.dialogInstance4.width = '35%';   
                this.dialogInstance4.height = '190px';
                this.dialogInstance4.minHeight = '195px';
                this.dialogInstance4.buttons = this.dialogButtons5;   
                this.dialogInstance4.show();   
                return;
            }
            let obj = { 
                ID_INCARICO: args.rowData.IdIncarico,
                GUID_INCARICO: args.rowData.GuidIncarico,
                ID_CAT: args.rowData.IdCAT,
                VERSIONE: args.rowData.Versione,
                DATA_SOPRALLUOGO: args.rowData.DataSopralluogo,
                ID_INCARICO_COMP: args.rowData.IdIncaricoCompagnia,
                SIGLA_PROVINCIA: args.rowData.Provincia,
                COMUNE: args.rowData.Citta,
                CAP: args.rowData.CAP,
                INDIRIZZO: args.rowData.Indirizzo,
                NUM_CIVICO: /^[0-9\s]+$/.test(args.rowData.Indirizzo?.trim().slice(-3).replace(/\s/g, '')) ? args.rowData.Indirizzo?.trim().slice(-3).replace(/\s/g, '') : 'SNC'
            }
            sessionStorage.setItem("JellyfishTechMobileDatiInvioIncaricoSede", JSON.stringify(obj));
            if (args.rowData.Stato === 'DAINV') {
                this.dialogInstance1.content = "La documentazione relativa all'incarico verrà inviata in sede e l'incarico medesimo verrà portato nello stato <span style='font-size:15px;font-weight:bolder'>INVIATI</span>. Assicurarsi di effettuare questa operazione nel momento corretto in quanto non sarà più possibile modificare l'incarico inviato, ma solamente duplicarlo per inviare eventuali modifiche e documentazione aggiuntiva. Confermare l'invio in sede?";
                this.dialogInstance1.height = '230px';
                this.dialogInstance1.minHeight = '230px';
                this.dialogInstance1.width = '35%';           
                this.dialogInstance1.show();   
            }
            else {
                this.dialogInstance4.content = "Si sta inviando in sede un'incarico che risulta essere già stato inviato. Questa operazione dovrebbe essere fatta solo su richiesta della sede oppure nel caso in cui l'incarico inviato in precedenza abbia avuto qualche problema durante l'invio e risulti non essere arrivato oppure arrivato parzialmente. Selezionare il tipo di reinvio in sede desiderato per confermare l'operazione";
                this.dialogInstance4.width = '35%';   
                this.dialogInstance4.height = '190px';
                this.dialogInstance4.minHeight = '195px';
                this.dialogInstance4.buttons = this.dialogButtons6;   
                this.dialogInstance4.show();   
            }
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-clone') > -1) {
            this.salvaRigaSel = { 
                IdIncarico: args.rowData.IdIncarico,
                GuidIncarico: args.rowData.GuidIncarico
            }            
            this.dialogInstance2.content = `Si vuole duplicare l'incarico con ID <span style='font-size:17px;font-weight:bolder'>${args.rowData.IdIncarico}</span>. Questo servirà per modificare i dati dell'incarico e a mandare della documentazione aggiuntiva. Confermare la duplicazione dell'incarico?`;
            this.dialogInstance2.height = '180px';
            this.dialogInstance2.minHeight = '180px';
            this.dialogInstance2.width = '40%';           
            this.dialogInstance2.show();              
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-archive') > -1) {
            this.salvaRigaSel = { 
                IdIncarico: args.rowData.IdIncarico,
                GuidIncarico: args.rowData.GuidIncarico
            }            
            this.dialogInstance3.content = `Si vuole archiviare l'incarico con ID <span style='font-size:17px;font-weight:bolder'>${args.rowData.IdIncarico}</span>. Con questa operazione l'incarico verrà portato nello stato <span style='font-size:15px;font-weight:bolder'>ARCHIVIATI</span> e la documentazione relativa verrà cancellata. L'incarico non sarà più visualizzabile ma solamente duplicabile. Questa operazione non è ripristinabile. Confermare l'archiviazione dell'incarico?`;
            this.dialogInstance3.height = '180px';
            this.dialogInstance3.minHeight = '180px';
            this.dialogInstance3.width = '40%';           
            this.dialogInstance3.show();              
        }
    }
    onChangeStati(args) {
        sessionStorage.setItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi', args.value);
        if (args.isInteracted)
            this.caricaDati();
    }
    ddlStatiToolbar() {
        return (
            <DropDownListComponent id='ddlStati' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance = ddlObj } dataSource={this.dsStati} change={this.onChangeStati.bind(this)} allowFiltering={false} filterType='Contains'
               style={{width: '15vw'}} placeholder="Selezionare uno stato da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
       );           
    }
    invioIncaricoSede(completo) {
        this.toggleBlocking();
        const Utente = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE,
              IdIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO,
              GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).GUID_INCARICO;
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/invioIncaricoSede/${Utente}/${IdIncarico}/${GuidIncarico}`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }                 
            this.toggleBlocking();
            setTimeout(() => {       
                toast.success(`Incarico ${IdIncarico} inviato in sede ACT con successo!!`, {
                    containerId: 'toastContainer1',
                    //onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });  
                if (completo) {
                    if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).VERSIONE === 1)
                        this.chiudiIncaricoJFTECH();      
                    else {
                        this.inviaEmailNotificaNuovaDocs(); 
                        const IdIncarico2 = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.indexOf('-') === -1 ? JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.substring(0, JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.indexOf('-'));
                        const dataObj = {
                            IdInternoSinistro: IdIncarico2,
                            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE.toUpperCase(),
                            DescrNota: `Comunicazione dal CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} :<br/><br/>Spett.le ACT,<br/><br/>siamo a comunicare di aver inviato nuova documentazione relativa al ID Incarico <span style='font-weight:bold'>${IdIncarico2}</span><br/><br/><br/><br/>Cordiali saluti`,
                        };
                        const QueryExec = "INSERT INTO [01003] SELECT [01001].id_sinistro, null, @descr_nota"  +
                                          ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null FROM [01001] WHERE [01001].id_interno_sinistro = @id_interno_sinistro";        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                            const QueryExec2 = `SELECT id_compagnia FROM [01001] WHERE id_interno_sinistro = '${IdIncarico2}'`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'GET', QueryExec2, '', retData2 => {
                                if (retData2[0].id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI
                                    && !CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO_COMP))
                                    this.invioDatiSopralluogoUnipolSAI();
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });                        
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });  
                    } 
                }     
                this.caricaDati();
            }, 100);
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false)
    }
    duplicaIncarico() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const dataObj = this.salvaRigaSel;
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/duplicaIncarico`, JSON.stringify(dataObj), 'POST',
              'application/json', token, retData => { 
                    if (retData.response === 'KO') {
                      let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                      toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                          onClose: () => toast.dismiss(),                    
                          position: "bottom-right",
                          autoClose: 15000,
                          hideProgressBar: false,            
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                          newestOnTop: true,
                          rtl: false,
                          pauseOnFocusLoss: true
                      });                     
                      return;
                    }    
                    if (retData.response === 'OK') {    
                        toast.success(`Incarico ${dataObj.IdIncarico} duplicato con successo!!`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });         
                        sessionStorage.setItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi', 'DAINV');
                        this.caricaDati();             
                    }  
                    //this.toggleBlocking();
        }, error => {
              let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
              toast.warn(messaggio, {
                  containerId: 'toastContainer1',
                  onClose: () => toast.dismiss(),
                  position: "bottom-right",
                  autoClose: 15000,
                  hideProgressBar: false,            
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  newestOnTop: true,
                  rtl: false,
                  pauseOnFocusLoss: true})
        }, true, false);
    }
    archiviaIncarico() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const codUtente = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE;
        const dataObj = this.salvaRigaSel;
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/archiviaIncarico/${codUtente}`, JSON.stringify(dataObj), 'POST',
              'application/json', token, retData => { 
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                     
                        return;
                    }    
                    if (retData.response === 'OK') {    
                        toast.success(`Incarico ${dataObj.IdIncarico} archiviato con successo!!`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });         
                        sessionStorage.setItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi', 'ARCH');
                        this.caricaDati();             
                    }  
                    //this.toggleBlocking();
        }, error => {
              let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
              toast.warn(messaggio, {
                  containerId: 'toastContainer1',
                  onClose: () => toast.dismiss(),
                  position: "bottom-right",
                  autoClose: 15000,
                  hideProgressBar: false,            
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  newestOnTop: true,
                  rtl: false,
                  pauseOnFocusLoss: true})
        }, true, false);
    }
    archiviaMultiplaIncarico(thisClone) {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const codUtente = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE;
        thisClone.toggleBlocking();
        thisClone.gridInstance.getSelectedRecords().forEach(elm => {
            const dataObj = elm;
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/archiviaIncarico/${codUtente}`, JSON.stringify(dataObj), 'POST',
                  'application/json', token, retData => { 
                        if (retData.response === 'KO') {
                            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });                     
                            return;
                        }    
                        if (retData.response === 'OK') {    
                            toast.success(`Incarico ${dataObj.IdIncarico} archiviato con successo!!`, {
                                containerId: 'toastContainer1',
                                //onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });         
                        }  
            }, error => {
                  let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                  toast.warn(messaggio, {
                      containerId: 'toastContainer1',
                      onClose: () => toast.dismiss(),
                      position: "bottom-right",
                      autoClose: 15000,
                      hideProgressBar: false,            
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                      newestOnTop: true,
                      rtl: false,
                      pauseOnFocusLoss: true})
            }, true, false);    
        });
        thisClone.toggleBlocking();
        sessionStorage.setItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi', 'ARCH');
        thisClone.caricaDati();             
    }
    attivaArchMult(args) {
        this.gridInstance.allowSelection = true;
        document.getElementById('BtnApplicaArchMult').style.removeProperty('display');
        document.getElementById('BtnAnnullaArchMult').style.removeProperty('display');
        document.getElementById('BtnAttivaArchMult').ej2_instances[0].disabled = true;
        this.dialogInstance4.content = `Selezionare uno o più incarichi da archiviare. Per effettuare la selezione è necessario cliccare/toccare il checkbox nella prima colonna della tabella. Alla fine della selezione, cliccare/toccare il bottone <span style='font-size:15px;font-weight:bolder'>APPLICA</span> per effettuare l'operazione. Cliccare/toccare il bottone <span style='font-size:15px;font-weight:bolder';color:red>ANNULLA</span> per annullare l'intera operazione.`;
        this.dialogInstance4.width = '35%';   
        this.dialogInstance4.height = '190px';
        this.dialogInstance4.minHeight = '195px';
        this.dialogInstance4.buttons = this.dialogButtons5;   
        this.dialogInstance4.show();              
    }
    applicaArchMult(args) {
        if (this.gridInstance.getSelectedRecords().length === 0)
            return;
        this.dialogInstance4.content = `Si vuole effettuare l'archiviazione multipla degli incarichi selezionati. Con questa operazione tutti gli incarichi verranno portati nello stato <span style='font-size:15px;font-weight:bolder'>ARCHIVIATI</span> e la documentazione relativa ai medesimi verrà cancellata. Gli incarichi non saranno più visualizzabili ma solamente duplicabili. Questa operazione non è ripristinabile. Confermare l'archiviazione degli incarichi?`;
        this.dialogInstance4.height = '190px';
        this.dialogInstance4.minHeight = '195px';
        this.dialogInstance4.width = '40%'; 
        this.dialogInstance4.buttons = this.dialogButtons4;          
        this.dialogInstance4.show();              
    }
    annullaArchMult(args) {
        this.gridInstance.selectRows([]);
        this.gridInstance.allowSelection = false;
        document.getElementById('BtnApplicaArchMult').style.display = 'none';
        document.getElementById('BtnAnnullaArchMult').style.display = 'none';
        document.getElementById('BtnAttivaArchMult').ej2_instances[0].disabled = false;
    }
    chiudiIncaricoJFTECH() {
        const dataObj = {
            IdInternoSinistro: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO,
            IdCat: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_CAT,
        };
        const QueryExec = `UPDATE [01004] SET [01004].chiusa = 1, [01004].t_riconsegnato=GETDATE() FROM [01001] INNER JOIN [01004] ON [01001].id_sinistro = [01004].id_sinistro WHERE [01004].id_cat = @id_cat AND [01001].id_interno_sinistro = @id_interno_sinistro`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            this.getStatoAzione(13).then(ret => {
                const id_stato_tmp = ret.stato;
                if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                    this.aggiornaStatoSinistro(id_stato_tmp).then(ret2 => {
                        if (ret2 === 'OK') {   
                            const QueryExec2 = `SELECT id_compagnia FROM [01001] WHERE id_interno_sinistro = '${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO}'`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'GET', QueryExec2, '', retData2 => {
                                if (retData2[0].id_compagnia.toString() === CustomJS.codiceCompagniaUnipolSAI
                                    && !CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO_COMP))
                                    this.invioDatiSopralluogoUnipolSAI();
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            });       
                        }
                    }).catch(messaggio => { 
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    });        
                }
                this.getAzioneNota(13).then(ret3 => {
                    if (ret3 === true) {
                        const dataObj = {
                            IdInternoSinistro: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO,
                            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE.toUpperCase(),
                            DescrNota: `Restituito sopralluogo dal CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} in data ${moment(new Date()).format('DD/MM/YYYY')}`,
                            Stato: id_stato_tmp
                        };
                        const QueryExec = "INSERT INTO [01003] SELECT [01001].id_sinistro, null, @descr_nota"  +
                                          ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato FROM [01001] WHERE [01001].id_interno_sinistro = @id_interno_sinistro";        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });
                    }
                    this.getStatoAzione(5).then(ret => {
                        const id_stato_tmp = ret.stato;
                        if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                            this.aggiornaStatoSinistro(id_stato_tmp).then(ret2 => {
                                if (ret2 === 'OK') {     
                                }
                            }).catch(messaggio => { 
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            });        
                        }
                        this.getAzioneNota(5).then(ret3 => {
                            if (ret3 === true) {
                                const dataObj = {
                                    IdInternoSinistro: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO,
                                    Utente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE.toUpperCase(),
                                    DescrNota: `Chiuso il CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} con data ${moment(new Date()).format('DD/MM/YYYY')}`,
                                    Stato: id_stato_tmp
                                };
                                const QueryExec = "INSERT INTO [01003] SELECT [01001].id_sinistro, null, @descr_nota"  +
                                                  ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato FROM [01001] WHERE [01001].id_interno_sinistro = @id_interno_sinistro";        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                            }
                        }).catch(messaggio => { 
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                
                    }).catch(messaggio => { 
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    });
                }).catch(messaggio => { 
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                });                
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });
        }, error => {
            this.dialogInstance4.content = CustomJS.messaggioErroreAggiornamentoJFTECH.replace('{0}', JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO);
            this.dialogInstance4.height = '210px';
            this.dialogInstance4.minHeight = '210px';
            this.dialogInstance4.width = '30%';  
            this.dialogInstance4.buttons = this.dialogButtons5;         
            this.dialogInstance4.show(); 
            this.inviaEmailAvvisoErroreAggiornamento();
        }); 
    }
    invioDatiSopralluogoUnipolSAI() {
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "DatiSopralluogo",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO_COMP,
                    SopralluogoEffettuato: 'S',
                    DataAvvenutoSopralluogo: moment(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).DATA_SOPRALLUOGO, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    CodiceNazioneIndirizzoSpralluogo: 'IT',
                    SiglaProvinciaIndirizzoSopralluogo: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).SIGLA_PROVINCIA,
                    CodiceComuneIndirizzoSopralluogo: _.find(ElencoComuni, elm => { return elm.comune.toUpperCase() === JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).COMUNE?.toUpperCase() })?.cod_fisco,
                    CapIndirizzoSopralluogo: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).CAP,
                    IndirizzoIndirizzoSopralluogo: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).INDIRIZZO,
                    NumeroCivicoIndirizzoSopralluogo: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).NUM_CIVICO,
                    NoteSopralluogo: null,
                    PresenzaCriticitaSinistro: 'N',
                    NoteCriticitaSinistro: null
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1', 
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, false);
    }
    inviaEmailAvvisoErroreAggiornamento() {
        if (CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].INDIRIZZO_EMAIL))
            return;
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const testoEmail = `Spett.le CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE},<br/><br/>con la presente siamo ad avvisare del fatto che l'operazione di aggiornamento dell'applicazione lato sede ACT (Jellyfish Technology) per la chiusura dell'incarico <span style='font-weight:bold'>${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO}</span>, NON è andata a buon fine a causa di un imprecisabile problema tecnico.<br>Siete pregati di riprovare più tardi e nel caso in cui il problema persista, sarebbe necessario avvisare la sede ACT della problematica in oggetto.<br/><br/><br/><br/>Cordiali saluti<br/><br/>&nbsp;&nbsp;&nbsp;Il supporto Jellyfish CAT Mobile`;
        const testoSoggetto = `Jellyfish CAT Mobile - Avviso di errore aggiornamento applicazione lato sede ACT (Jellyfish Technology) per chiusura ID Incarico: ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO}`;
        const destinatario = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].INDIRIZZO_EMAIL;
        const dataObj = {
            server: 'mail.gruppoallconsulting.com',
            porta: 465,
            utente: 'jellyfish@gruppoallconsulting.com',
            password: 'r%9W#4wpFdRt',
            SSL: true,
            mittente: 'Jellyfish CAT Mobile',
            destinatario: destinatario, //'stefano.giannotta@digitalpraesidium.it' PER TEST   //destinatario PER PROD
            emailMittente: 'jellyfish@gruppoallconsulting.com',
            emailCC: 'cat@actsrl.it',
            soggetto: testoSoggetto,
            messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: `<span style="color: darkblue;font-size: 20px;font-weight: 500;">${testoEmail}</span>`
        }
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L'errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                            
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                else {
                    toast.success('Email avviso errore aggiornamento lato sede ACT inviata con successo!!', {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
        }, true, false)
    }  
    inviaEmailNotificaNuovaDocs() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const IdIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.indexOf('-') === -1 ? JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.substring(0, JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO.indexOf('-'));
        const testoEmail = `Spett.le ACT,<br/><br/>siamo a comunicare di aver inviato nuova documentazione relativa al ID Incarico <span style='font-weight:bold'>${IdIncarico}</span><br/><br/><br/><br/>Cordiali saluti<br/><br/>&nbsp;&nbsp;&nbsp;${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE}`;
        const testoSoggetto = `Jellyfish CAT Mobile - Invio nuova documentazione da parte di ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} per ID Incarico: ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO}`;
        const dataObj = {
            server: 'mail.gruppoallconsulting.com',
            porta: 465,
            utente: 'jellyfish@gruppoallconsulting.com',
            password: 'r%9W#4wpFdRt',
            SSL: true,
            mittente: 'Jellyfish CAT Mobile',
            destinatario: 'cat@actsrl.it', //'stefano.giannotta@digitalpraesidium.it' PER TEST   // PER PROD
            emailMittente: 'jellyfish@gruppoallconsulting.com',
            emailCC: '',
            soggetto: testoSoggetto,
            messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: `<span style="color: darkblue;font-size: 20px;font-weight: 500;">${testoEmail}</span>`
        }
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                            
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                else {
                    toast.success('Email con Nota relativa all\'incarico inviata con successo!!', {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });  
                }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
        }, true, false)
    }  
    getStatoAzione(IdAzione) {
        const QueryExec = `SELECT [02010].*, [02004].descrizione AS descr_stato FROM [02010] LEFT JOIN [02004] ON [02010].id_stato = [02004].id_tipo_stato WHERE [02010].id_azione = ${IdAzione}`; 
        let num = 0, descr_stato = ''; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {                    
                    num = retData[0].id_stato;
                    descr_stato = retData[0].descr_stato;
                } 
                resolve({ stato: num, descr_stato: descr_stato });
            }, messaggio => {               
                reject(messaggio);
            });           
        });
    }
    getAzioneNota(IdAzione) {
        const QueryExec = encodeURIComponent(`SELECT [02010].* FROM [02010] WHERE [02010].id_azione = ${IdAzione}`); 
        let num = false;  
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) 
                   num = retData[0].genera_nota;
                resolve(num);
            }, messaggio => {
                reject(messaggio);
            });                     
        });
    }
    aggiornaStatoSinistro(stato) {
        return new Promise((resolve,reject) => {  
            const dataObj3 = {
                IdInternoSinistro : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).ID_INCARICO,
                Stato: stato,
                TSopralluogo: moment(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiInvioIncaricoSede')).DATA_SOPRALLUOGO, 'DD/MM/YYYY').format('YYYY-MM-DD')         
            };  
            const QueryExec = `UPDATE [01001] SET [01001].id_stato = @stato, t_sopralluogo = @t_sopralluogo, t_sopralluogo_concordato = @t_sopralluogo WHERE [01001].id_interno_sinistro = @id_interno_sinistro`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj3), retData3 => {
                resolve('OK');  
            }, messaggio => {
                reject(messaggio);
            });  
        });      
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUILogin' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog1" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog} width='35%'
                                        ref={dialog => this.dialogInstance1 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='200px' minHeight='200px' isModal={true} 
                                        content=''>
                </DialogComponent> 
                <DialogComponent id="defaultDialog2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog} width='35%'
                                        ref={dialog => this.dialogInstance2 = dialog} header='Attenzione!!' buttons={this.dialogButtons2} height='200px' minHeight='200px' isModal={true} 
                                        content=''>
                </DialogComponent> 
                <DialogComponent id="defaultDialog3" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog} width='35%'
                                        ref={dialog => this.dialogInstance3 = dialog} header='Attenzione!!' buttons={this.dialogButtons3} height='200px' minHeight='200px' isModal={true} 
                                        content=''>
                </DialogComponent>
                <DialogComponent id="defaultDialog4" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog} width='35%'
                                        ref={dialog => this.dialogInstance4 = dialog} header='Attenzione!!' buttons={this.dialogButtons4} height='200px' minHeight='200px' isModal={true} 
                                        content=''>
                </DialogComponent>
                <div className='container-fluid'> 
                   <div className='row'>&nbsp;</div>
                    <div className="row">                        
                        <div id='divTitoloPagina' className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ marginTop: '5px'}}>
                            <span style={{ fontSize: '2.5vw', fontWeight: 'bolder', color:'#133B95'}}>SITUAZIONE INCARICHI - {JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE}</span>
                        </div>                                           
                    </div>
                    <div className='row'>
                        <div id='rigaAttivazArchMult' className='col-lg-4 offset-lg-8 col-md-6 offset-md-6 col-sm-6 offset-sm-6 col-xs-6 offset-xs-6 text-end' style={{ display: 'none' }}>  
                            <ButtonComponent id='BtnAttivaArchMult' alt='Attiva Archiviazione Multipla' onClick={this.attivaArchMult.bind(this)} cssClass='e-info'>Attiva Archiviazione Multipla</ButtonComponent>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ButtonComponent id='BtnApplicaArchMult' alt='Applica Archiviazione Multipla' onClick={this.applicaArchMult.bind(this)} style={{ display: 'none' }} cssClass='e-success'>Applica</ButtonComponent>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ButtonComponent id='BtnAnnullaArchMult' alt='Annulla Archiviazione Multipla' onClick={this.annullaArchMult.bind(this)} style={{ display: 'none' }} cssClass='e-danger'>Annulla</ButtonComponent>     
                        </div>
                    </div>
                    <div className='row'>  
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>               
                            <GridComponent id="GridSituazioneIncarichi" ref={ grid => this.gridInstance = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick.bind(this)} 
                                editSettings={this.editSettings} allowSelection={false} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                allowTextWrap={true} allowPaging={false} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={false} 
                                selectionSettings={{ type: 'Multiple', checkboxMode: 'ResetOnRowClick', mode: 'Row', enableToggle: true, checkboxOnly: true }}>
                                <ColumnsDirective>
                                    <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                                    <ColumnDirective field='IdIncarico' headerText='ID Incarico' width='11%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='DataIncarico' headerText="Data Incarico" format='dd/MM/yyyy' width='13%' headerTemplate={headerTemplate} template={fieldTemplate}  textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='NomeIndirizzoCliente' headerText="Nominativo e Indirizzo Cliente" width='65%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective field='Comandi' headerText="" width='11%' commands={this.commands} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Right'></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort]} />
                            </GridComponent>  
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }    
    componentDidMount() {
        setTimeout(() => { 
            if (CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi')))
                sessionStorage.setItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi', 'DAINV');
            this.caricaDati();
            if (CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTechMobileMessaggioArchiviazioneVisualizzato'))) {
                toast.warn(CustomJS.messaggioArchiviazioneIncarichi, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 20000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                sessionStorage.setItem('JellyfishTechMobileMessaggioArchiviazioneVisualizzato', 'true');
            }
        }, 500);
    }
    caricaDati() {
        const Stato = sessionStorage.getItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi'),
              Utente = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].GUID_UTENTE;
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/situazioneIncarichi/${Utente}/${Stato}`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }
            GridData = [];
            retData.forEach(elm => {
                GridData.push({ GuidIncarico: elm.GUID, IdIncarico: elm.ID_INTERNO, DataIncarico: moment(elm.DATA_INCARICO).format('DD/MM/YYYY'), DataOraAppunt: CustomJS.isNullEmpty(elm.DATA_ORA_APPUNT) ? null : moment(elm.DATA_ORA_APPUNT).format('DD/MM/YYYY HH:mm'), 
                                DataSopralluogo: CustomJS.isNullEmpty(elm.DATA_SOPRALLUOGO) ? null : moment(elm.DATA_SOPRALLUOGO).format('DD/MM/YYYY'), IdIncaricoCompagnia: elm.ID_INCARICO_COMP,
                                DataChiusura: moment(elm.DATA_CHIUSURA).format('DD/MM/YYYY'), Nominativo: elm.NOMINATIVO, Indirizzo: elm.INDIRIZZO, Citta: elm.CITTA, CAP: elm.CAP, 
                                Provincia: elm.PROVINCIA, Nazione: elm.NAZIONE, NomeIndirizzoCliente: elm.INDIRIZZO_COMPLETO, Contatto: elm.CONTATTO, Telefono: elm.TELEFONO, 
                                Email: elm.EMAIL, NotaIncarico: elm.NOTA, Stato: elm.STATO, PresenzaDocs: elm.PRESENZA_DOCS, AppuntInviato: elm.APPUNT_INVIATO, IdCAT: elm.ID_CAT, Versione: elm.VERSIONE })
            });
            this.ddlInstance.value = sessionStorage.getItem('JellyfishTechMobileFiltroStatoSituazioneIncarichi');
            this.gridInstance.allowSelection = false;  
            this.gridInstance.selectRows([]);
            document.getElementById('BtnApplicaArchMult').style.display = 'none';
            document.getElementById('BtnAnnullaArchMult').style.display = 'none';
            document.getElementById('BtnAttivaArchMult').ej2_instances[0].disabled = false;
            if (this.ddlInstance.value === 'INV')
                this.gridInstance.columns[0].visible = true;
            else
                this.gridInstance.columns[0].visible = false;   
            this.gridInstance.refreshColumns();
            this.gridInstance.dataSource = GridData;
            if (this.ddlInstance.value === 'INV' && this.gridInstance.dataSource.length > 0)
                $('#rigaAttivazArchMult').show();
            else
                $('#rigaAttivazArchMult').hide();
            this.toggleBlocking();
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
}