import * as React from 'react';
import { createRoot } from 'react-dom/client'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEinject, Image, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport,  CommandColumn, Filter, Inject, Sort, InfiniteScroll, Virtualization } from '@syncfusion/ej2-react-grids';
import SituazioneIncarichi from './SituazioneIncarichi';
import PaginaDocumentazione from './PaginaDocumentazione';
import { SampleBase } from './sampleBase';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { Browser, addClass, removeClass, deleteObject } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import { random, isUndefined } from 'underscore';
import moment from 'moment';
import ElencoComuni from '../assets/ListaComuni.json';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.9vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'DataOraInvioMessaggio' ? '#133B95' : 'purple';
    let renderDiv = <div><span style={{ fontSize: '.8vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;

    return (
        renderDiv
    );
}

export default class DettaglioIncarico extends SampleBase {
    constructor() {
        super(...arguments);
        this.childKey = 0;
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.commands = [{ type: "Copia messaggio in casella testo", buttonOption: { iconCss: "fas fa-arrow-left fa-15x", cssClass: "e-details" }}];
        this.tabHeaderText = [{ text: "INFO DI BASE" }, { text: "DOCUMENTAZIONE" }];
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStati = JSON.parse(sessionStorage.getItem('JellyfishTechMobileTabellaSTP')).filter(elm => { return !CustomJS.isNullEmpty(elm.VALUE) });
        this.dsProv = JSON.parse(sessionStorage.getItem('JellyfishTechMobileTabellaPRV'));
        this.dsNaz = JSON.parse(sessionStorage.getItem('JellyfishTechMobileTabellaNAZ'));
        this.RTEitems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateTable', 'CreateLink', '|', 'ClearFormat', 'Print',
            'FullScreen', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.RTEiframeSettings = { enable: false };
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance1.hide();
                setTimeout(() => {
                    this.cancellaDocs();
                    sessionStorage.removeItem('JellyfishTechMobileDatiIncarico');
                    ++this.childKey;
                    if (document.getElementById('defaultDialogDoc3'))
                        document.getElementById('defaultDialogDoc3').ej2_instances[0].destroy();    
                    const container = document.getElementById('contenutoDinamico2');
                    const root = createRoot(container); 
                    root.render(<SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>); 
                }, 200);
            },
            buttonModel: {
                content: 'ESCI',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogButtons2 = [{
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'OK',
                cssClass: 'e-primary',
                isPrimary: true
            }
        }];
        this.dialogButtons3 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.tabInstance.hideTab(1, false);
                document.getElementById('IdIncarico').ej2_instances[0].enabled = false;
                document.getElementById('btnInviaAppuntSede').ej2_instances[0].disabled = false;
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogButtons4 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.inviaEmailNotificaAppunt();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogButtons5 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.inviaEmailNotaIncarico();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.formOptions = {
            rules: {
                'IdIncarico': {
                    required: [true, 'ID Incarico è un campo obbligatorio'],
                    maxLength: [50, 'Lunghezza massima 50 caratteri'],
                    caratteriInvalidi: [CustomJS.checkInvalidChars, 'ID Incarico contiene caratteri invalidi']
                },
                'Stato': {
                    required: [true, 'Stato è un campo obbligatorio']
                },
                'DataIncarico': {
                    required: [true, 'Data Incarico è un campo obbligatorio']
                },
                'Nominativo': {
                    // required: [true, 'Nominativo è un campo obbligatorio'],
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                },
                'Indirizzo': {
                    //required: [true, 'Indirizzo è un campo obbligatorio'],
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                },
                'Citta': {
                    //required: [true, 'Citta è un campo obbligatorio'],
                    maxLength: [50, 'Lunghezza massima 50 caratteri']
                },
                'CAP': {
                    //required: [true, 'CAP è un campo obbligatorio'],
                    maxLength: [10, 'Lunghezza massima 10 caratteri']
                },
                'Provincia': {
                    //required: [true, 'Provincia è un campo obbligatorio']
                },
                'Email': {
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                },
                'Contatto': {
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                },
                'Telefono': {
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                }
            }
        };
        this.salvaNota = null;
    }
    state = {
        blocking: false,
        showDialog1: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    onChangeStati(args) {
    }
    onChangeDatePicker() {
        document.getElementById('divAppuntInviato').innerHTML = '';
    }
    handleFullScreen(e) {
        let sbCntEle = document.querySelector('.sb-content.e-view');
        let sbHdrEle = document.querySelector('.sb-header.e-view');
        let leftBar;
        let transformElement;
        if (Browser.isDevice) {
            leftBar = document.querySelector('#right-sidebar');
            transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
        }
        else {
            leftBar = document.querySelector('#left-sidebar');
            transformElement = document.querySelector('#right-pane');
        }
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            addClass([leftBar], ['e-close']);
            removeClass([leftBar], ['e-open']);
            if (!Browser.isDevice) {
                transformElement.style.marginLeft = '0px';
            }
            transformElement.style.transform = 'inherit';
        }
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            removeClass([leftBar], ['e-close']);
            if (!Browser.isDevice) {
                addClass([leftBar], ['e-open']);
                transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
            }
            transformElement.style.transform = 'translateX(0px)';
        }
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 2; ind++)
            document.getElementById('GridLogMessaggi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        setTimeout(() => {
            $(`#GridLogMessaggi tr`).each(function(elm) {
                $($(this).find('button')[0]).prop("type", "button")
            });
        }, 100);
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-arrow-left') > -1) {
            this.RTEObj.value = args.rowData.TestoMessaggio;
        }
    }
    tabContent0(args) {
        return (<React.Fragment>
            <form id="formIncarico" method="post">
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color: '#133B95' }}>INFO DI BASE</span>
                                        </div>
                                    </div>
                                    <div className="e-card-content">
                                        <div className="row">
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='IdIncarico' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico} floatLabelType='Auto' onBlur={this.onBlurIdIncarico.bind(this)} placeholder='ID Incarico' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DropDownListComponent id='Stato' fields={this.ddlFields} ref={ddlObj => this.ddlStatiInstance = ddlObj}
                                                    readonly={true} dataSource={this.dsStati} change={this.onChangeStati.bind(this)}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato}
                                                    placeholder="Stato" floatLabelType='auto' popupHeight="250px" cssClass='e-outline' />
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DatePickerComponent id='DataIncarico' readonly={true} allowEdit={false} format='dd/MM/yyyy' showClearButton={false}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).DataIncarico} floatLabelType='Auto' placeholder='Data Incarico' cssClass='e-outline'></DatePickerComponent>
                                            </div>
                                            <div id='divDataChiusura' className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DatePickerComponent id='DataChiusura' readonly={true} allowEdit={false} format='dd/MM/yyyy' showClearButton={false}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).DataChiusura} floatLabelType='Auto' placeholder='Data Chiusura' cssClass='e-outline'></DatePickerComponent>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div tabIndex="0" className="e-card" id="main_card">
                                                                    <div className="e-card-header">
                                                                        <div className="e-card-header-caption">
                                                                            <span style={{ fontSize: '1.7vw', fontWeight: 'bolder', paddingLeft: '5px', color: '#133B95' }}>DATA/ORA APPUNTAMENTO/SOPRALLUOGO</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="e-card-content">
                                                                        <DateTimePickerComponent id='DataOraAppunt' readonly={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'} value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).DataOraAppunt} change={this.onChangeDatePicker}
                                                                            showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'DAINV'} step={15} ref={dtp => this.Dtp = dtp} format='dd/MM/yyyy HH:mm' allowEdit={false} cssClass='e-outline'></DateTimePickerComponent>
                                                                        <ProgressButtonComponent id='btnInviaAppuntSede' content="Invia notifica in sede ACT" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }} disabled={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV' || JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW'}
                                                                            animationSettings={{ effect: 'SlideRight' }} created={this.ProgrButtCreated3.bind(this)} cssClass="e-fill e-info"></ProgressButtonComponent>
                                                                        <div id='divAppuntInviato' style={{ paddingTop: '2px' }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DatePickerComponent id='DataSopralluogo' allowEdit={false} format='dd/MM/yyyy' showClearButton={true} disabled={CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).DataSopralluogo)}
                                                    readonly={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'} value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).DataSopralluogo} floatLabelType='Auto' placeholder='Data Effettivo Sopralluogo' cssClass='e-outline'></DatePickerComponent>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Nominativo' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Nominativo} floatLabelType='Auto' placeholder='Nominativo' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Indirizzo' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Indirizzo} floatLabelType='Auto' placeholder='Indirizzo' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Citta' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Citta} floatLabelType='Auto' placeholder='Citta' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-3 col-xs-3 ms-3 mt-3'>
                                                <TextBoxComponent id='CAP' type='number' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).CAP} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-3 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DropDownListComponent id='Provincia' fields={this.ddlFields} ref={ddlObj => this.ddlProvInstance = ddlObj}
                                                    dataSource={this.dsProv} allowFiltering={true} filterType='Contains'
                                                    readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Provincia}
                                                    placeholder="Provincia" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline' />
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <DropDownListComponent id='Nazione' fields={this.ddlFields} ref={ddlObj => this.ddlNazInstance = ddlObj}
                                                    dataSource={this.dsNaz} allowFiltering={true} filterType='Contains'
                                                    readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Nazione}
                                                    placeholder="Nazione" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline' />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Contatto' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Contatto} floatLabelType='Auto' placeholder='Contatto' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Telefono' showClearButton={false} readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Telefono} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 ms-3 mt-3'>
                                                <TextBoxComponent id='Email' readonly={true}
                                                    value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Email} floatLabelType='Auto' placeholder='Email' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ms-3" style={{ marginTop: '5px' }}>
                                                <span style={{ fontSize: '2.3vw', fontWeight: 'bolder', color: '#133B95' }}>Note Incarico</span>&nbsp;&nbsp;
                                                <span style={{ fontSize: '0.9vw', fontWeight: 'bolder', color: '#133B95' }}>(utilizzare la casella sottostante per inviare note estemporanee relative all'incarico. La nota può essere salvata prima dell'invio ed una volta inviata verrà mantenuta in uno storico visibile nella tabella sottostante sul lato destro)</span>
                                                <br/><br/>
                                                <ProgressButtonComponent id='btnInviaNotaSede' content="Invia nota in sede ACT" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }} style={{ marginBottom: '5px' }}
                                                    animationSettings={{ effect: 'SlideRight' }} created={this.ProgrButtCreated4.bind(this)} cssClass="e-fill e-info"></ProgressButtonComponent>
                                            </div>
                                            <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                                <RichTextEditorComponent id="NotaIncarico" ref={richtexteditor => { this.RTEObj = richtexteditor; }} showCharCount={true} enableHtmlEncode={false} readonly={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'}
                                                    maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='300px' actionBegin={this.handleFullScreen.bind(this)}
                                                    iframeSettings={this.RTEiframeSettings} value={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).NotaIncarico?.replace(/&amp;lt;/g, '<')?.replace(/&amp;gt;/g, '>')?.replace(/&quot;/g, '"')}>
                                                    <RTEinject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
                                                </RichTextEditorComponent>
                                            </div>
                                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                                <GridComponent id="GridLogMessaggi" ref={ grid => this.gridInstance = grid } commandClick={this.onCommandClick.bind(this)} allowSelection={false} enableHover={false}
                                                    dataBound={this.onDatabound.bind(this)} allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} height='300px'>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='DataOraInvioMessaggio' headerText="Data/Ora Invio" headerTextAlign='Center' textAlign='Center' format='dd/MM/yyyy HH:mm' width='20%' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                        <ColumnDirective field='TestoMessaggio' headerText="Testo" headerTemplate={headerTemplate} headerTextAlign='Center' textAlign='Left' disableHtmlEncode={false} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='7%' commands={this.commands} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort]} />
                                                </GridComponent> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>);
    }
    tabContent1(args) {
        return (<React.Fragment>
            <PaginaDocumentazione></PaginaDocumentazione>
        </React.Fragment>);
    }
    tabSelecting(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }
    onBlurIdIncarico() {
        if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico !== 'NEW'
            || CustomJS.isNullEmpty(document.getElementById('IdIncarico').ej2_instances[0].value?.trim()))
            return;
        if (!CustomJS.checkInvalidChars(document.getElementById('IdIncarico').ej2_instances[0])) {
            let messaggio = 'ID Incarico contiene caratteri invalidi. Sono permessi unicamente caratteri alfanumerici.'
            toast.warn(messaggio, {
                containerId: 'toastContainer2',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            return;
        }
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token,
            IdIncarico = document.getElementById('IdIncarico').ej2_instances[0].value?.trim();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/controlloEsisteIncarico/${IdIncarico}`, '', 'GET',
            '', token, retData => {
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    return;
                }
                if (retData.response === 'OK') {
                    this.dialogInstance1.buttons = this.dialogButtons3;
                    this.dialogInstance1.content = "E' necessario confermare il valore inserito relativo al ID Incarico. Una volta confermato, verrà sbloccata la sezione relativa alla documentazione e il valore non sarà più modificabile durante l'attuale inserimento. Confermare l'operazione?";
                    this.dialogInstance1.width = '35%';
                    this.dialogInstance1.height = '210px';
                    this.dialogInstance1.minHeight = '210px';
                    this.dialogInstance1.show();
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, false, false);
    }
    ProgrButtCreated1() {
        document.getElementById('btnSalvaEsci').ej2_instances[0].element.addEventListener('click', () => {
            if (this.formObject.validate()) {
                this.eseguiSalva(true);
            }
            else
                toast.error(CustomJS.messaggioErroreObbligo, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-left",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
        });
    }
    eseguiSalva(esci) {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token,
            GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW' ? CustomJS.createGuid4() : JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico;
        const dataObj = {
            IdIncarico: document.getElementById('IdIncarico').ej2_instances[0].value.trim(),
            DataIncarico: document.getElementById('DataIncarico').ej2_instances[0].value,
            DataOraAppunt: document.getElementById('DataOraAppunt').ej2_instances[0].value,
            DataSopralluogo: document.getElementById('DataSopralluogo').ej2_instances[0].value,
            Stato: document.getElementById('Stato').ej2_instances[0].value,
            Nominativo: document.getElementById('Nominativo').ej2_instances[0].value,
            Indirizzo: document.getElementById('Indirizzo').ej2_instances[0].value,
            Citta: document.getElementById('Citta').ej2_instances[0].value,
            CAP: document.getElementById('CAP').ej2_instances[0].value,
            Provincia: document.getElementById('Provincia').ej2_instances[0].value,
            Nazione: document.getElementById('Nazione').ej2_instances[0].value,
            Telefono: document.getElementById('Telefono').ej2_instances[0].value,
            Contatto: document.getElementById('Contatto').ej2_instances[0].value,
            Email: document.getElementById('Email').ej2_instances[0].value,
            Nota: this.RTEObj.value,
            GuidMessaggio: CustomJS.isNullEmpty(this.salvaNota) ? null : CustomJS.createGuid4(),
            GuidRifMessaggio: CustomJS.isNullEmpty(this.salvaNota) ? null : GuidIncarico,
            DataOraInvioMessaggio: CustomJS.isNullEmpty(this.salvaNota) ? null : moment(new Date()).format('YYYY-MM-DD HH:mm'),
            TestoMessaggio: CustomJS.isNullEmpty(this.salvaNota) ? null : this.salvaNota,
            CodUtente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE,
            GuidUtente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].GUID_UTENTE,
            ListaDocumentazione: CustomJS.isNullEmpty(document.getElementById('GridDocumentazione')) ? [] : document.getElementById('GridDocumentazione').ej2_instances[0].dataSource,
        };
        // if (!esci)
        //     this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/salvaIncarico/${GuidIncarico}`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    // if (!esci)
                    //     this.toggleBlocking();
                    return;
                }
                if (retData.response === 'OK' && esci) {
                    setTimeout(() => {
                        sessionStorage.removeItem('JellyfishTechMobileDatiIncarico');
                        ++this.childKey;
                        if (document.getElementById('defaultDialogDoc3'))
                            document.getElementById('defaultDialogDoc3').ej2_instances[0].destroy();    
                        const container = document.getElementById('contenutoDinamico2');
                        const root = createRoot(container); 
                        root.render(<SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>); 
                    }, 100);
                }
                // else if (!esci)
                //     this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                // if (!esci)
                //     this.toggleBlocking();
            }, true, false);
    }
    cancellaDocs() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const dataObj = {
            CodUtente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE,
            IdIncarico: document.getElementById('IdIncarico').ej2_instances[0].value?.trim(),
            ListaDocsCanc: CustomJS.isNullEmpty(document.getElementById('GridDocumentazione')) ? [] : document.getElementById('GridDocumentazione').ej2_instances[0].dataSource.filter(elm => elm.Stato === 'N')
        };
        if (dataObj.ListaDocsCanc.length === 0)
            return;
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/cancellaDocs`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    return;
                }
                if (retData.response === 'OK') {
                }
                //this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false);
    }
    inviaEmailNotificaAppunt() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const testoEmail = `Spett.le ACT,<br/><br/>siamo a comunicare che l’appuntamento è stato fissato per il giorno ${moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('DD/MM/YYYY')} alle ore ${moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('HH:mm')} con ${CustomJS.isNullEmpty(document.getElementById('Contatto').ej2_instances[0].value?.trim()) ? '(Contatto non specificato)' : document.getElementById('Contatto').ej2_instances[0].value?.trim()}<br/><br/><br/><br/>Cordiali saluti<br/><br/>&nbsp;&nbsp;&nbsp;${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE}`;
        const testoSoggetto = `Jellyfish CAT Mobile – ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} – ID Incarico: ${document.getElementById('IdIncarico').ej2_instances[0].value} – ${document.getElementById('Nominativo').ej2_instances[0].value}`;
        const dataObj = {
            server: 'mail.gruppoallconsulting.com',
            porta: 465,
            utente: 'jellyfish@gruppoallconsulting.com',
            password: 'r%9W#4wpFdRt',
            SSL: true,
            mittente: 'Jellyfish CAT Mobile',
            destinatario: 'cat@actsrl.it', //'stefano.giannotta@digitalpraesidium.it' PER TEST      // IN PROD 
            emailMittente: 'jellyfish@gruppoallconsulting.com',
            emailCC: '',
            soggetto: testoSoggetto,
            messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: `<span style="color: darkblue;font-size: 20px;font-weight: 500;">${testoEmail}</span>`
        }
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                else {
                    toast.success('Email di notifica appuntamento/sopralluogo inviata con successo!!', {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    document.getElementById('divAppuntInviato').innerHTML = '<span style="font-size: 1.2vw;font-weight:600;color:green;">Appuntamento inviato in sede</span>';
                    this.inserisciLogAppuntamenti();
                    this.inserisciAppuntamentoJFTECH();
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false)
    }
    inserisciLogAppuntamenti() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const dataObj = {
            IdIncarico: document.getElementById('IdIncarico').ej2_instances[0].value,
            DataOraAppunt: document.getElementById('DataOraAppunt').ej2_instances[0].value,
            CodiceUtente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/inserisciLogAppuntamenti`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    //this.toggleBlocking();
                    return;
                }
                if (retData.response === 'OK') {
                    document.getElementById('DataSopralluogo').ej2_instances[0].value = document.getElementById('DataOraAppunt').ej2_instances[0].value;
                    //document.getElementById('DataSopralluogo').ej2_instances[0].disabled = false;
                }
                //this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false);
    }
    inviaEmailNotaIncarico() {
        const token = JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token;
        const testoEmail = this.RTEObj.value;
        const testoSoggetto = `Jellyfish CAT Mobile – Nota da parte del CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} per ID Incarico: ${document.getElementById('IdIncarico').ej2_instances[0].value} – ${document.getElementById('Nominativo').ej2_instances[0].value}`;
        const dataObj = {
            server: 'mail.gruppoallconsulting.com',
            porta: 465,
            utente: 'jellyfish@gruppoallconsulting.com',
            password: 'r%9W#4wpFdRt',
            SSL: true,
            mittente: 'Jellyfish CAT Mobile',
            destinatario: 'cat@actsrl.it', //'stefano.giannotta@digitalpraesidium.it', PER TEST   'cat@actsrl.it', // IN PROD
            emailMittente: 'jellyfish@gruppoallconsulting.com',
            emailCC: '',
            soggetto: testoSoggetto,
            messaggioTesto: 'Messaggio di prova in formato testo...',
            messaggioHtml: `<span style="color: darkblue;font-size: 20px;font-weight: 500;">${testoEmail}</span>`
        }
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/sendEmail`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => {
                if (retData.response === 'KO')
                    toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                else {
                    toast.success('Email con Nota relativa all\'incarico inviata con successo!!', {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.inserisciNotaJFTECH();
                    setTimeout(() => {
                        caricaDati(this);
                    }, 600);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                })
            }, true, false)
    }
    inserisciNotaJFTECH() {
        const dataObj = {
            IdInternoSinistro: document.getElementById('IdIncarico').ej2_instances[0].value.indexOf('-') > -1 ? document.getElementById('IdIncarico').ej2_instances[0].value.substring(0, document.getElementById('IdIncarico').ej2_instances[0].value.indexOf('-')) : document.getElementById('IdIncarico').ej2_instances[0].value,
            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE.toUpperCase(),
            DescrNota: `Nota da parte del CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE} :<br/>${this.RTEObj.value}`,
        };
        const QueryExec = "INSERT INTO [01003] SELECT [01001].id_sinistro, null, @descr_nota" +
            ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 0, null FROM [01001] WHERE [01001].id_interno_sinistro = @id_interno_sinistro";
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            if (retData.response === 'OK') {
                this.salvaNota = this.RTEObj.value;
                this.RTEObj.value = null;
                this.eseguiSalva(false);
                this.salvaNota = null;
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer2',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    inserisciAppuntamentoJFTECH() {
        const dataObj = {
            IdInternoSinistro: document.getElementById('IdIncarico').ej2_instances[0].value.indexOf('-') > -1 ? document.getElementById('IdIncarico').ej2_instances[0].value.substring(0, document.getElementById('IdIncarico').ej2_instances[0].value.indexOf('-')) : document.getElementById('IdIncarico').ej2_instances[0].value,
            Utente: JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].UTENTE.toUpperCase(),
            DescrNota: `Notificato appuntamento da parte del CAT ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).data[0].RAGIONE_SOCIALE}  per il giorno ${moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('DD/MM/YYYY')} alle ore ${moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('HH:mm')} con ${CustomJS.isNullEmpty(document.getElementById('Contatto').ej2_instances[0].value?.trim()) ? '(Contatto non specificato)' : document.getElementById('Contatto').ej2_instances[0].value?.trim()}`,
            TSopralluogo: moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('YYYY-MM-DD HH:mm'),
            IdCat: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdCAT
        };
        const QueryExec = "INSERT INTO [01003] SELECT [01001].id_sinistro, null, @descr_nota" +
                          ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 0, null FROM [01001] WHERE [01001].id_interno_sinistro = @id_interno_sinistro;" + 
                          "UPDATE [01001] SET t_sopralluogo_concordato = @t_sopralluogo WHERE [01001].id_interno_sinistro = @id_interno_sinistro; UPDATE [01004] SET t_sopralluogo = @t_sopralluogo WHERE [01004].id_cat = @id_cat";
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            if (retData.response === 'OK') {
                if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncaricoCompagnia) 
                    && JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdCompagnia?.toString() === CustomJS.codiceCompagniaUnipolSAI
                    && JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Versione === 1)
                    this.invioEsitoContattoDanneggiatoUnipolSAI();
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer2',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    invioEsitoContattoDanneggiatoUnipolSAI() {
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "EsitoContattoDanneggiato",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncaricoCompagnia,
                    DataOraContatto: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                    CodiceEsitoContatto: '01',
                    DataOraConcordataSopralluogo: moment(document.getElementById('DataOraAppunt').ej2_instances[0].value).format('YYYY-MM-DD HH:mm'),
                    DataProssimoContatto: null,
                    CodiceNazioneIndirizzoSpralluogo: 'IT',
                    SiglaProvinciaIndirizzoSopralluogo: document.getElementById('Provincia').ej2_instances[0].value,
                    CodiceComuneIndirizzoSopralluogo: _.find(ElencoComuni, elm => { return elm.comune.toUpperCase() === document.getElementById('Citta').ej2_instances[0].value?.toUpperCase() })?.cod_fisco,
                    CapIndirizzoSopralluogo: document.getElementById('CAP').ej2_instances[0].value,
                    IndirizzoIndirizzoSopralluogo: document.getElementById('Indirizzo').ej2_instances[0].value,
                    NumeroCivicoIndirizzoSopralluogo: /^[0-9\s]+$/.test(document.getElementById('Indirizzo').ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '')) ? document.getElementById('Indirizzo').ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '') : 'SNC',
                    CellulareDanneggiato: document.getElementById('Telefono').ej2_instances[0].value,
                    EmailDanneggiato: document.getElementById('Email').ej2_instances[0].value
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer2',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer2',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer2', 
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, false);
    }
    ProgrButtCreated2() {
        document.getElementById('btnEsci').ej2_instances[0].element.addEventListener('click', () => {
            if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'DAINV') {
                this.dialogInstance1.buttons = this.dialogButtons1;
                this.dialogInstance1.content = CustomJS.messaggioConfermaUscita;
                this.dialogInstance1.width = '35%';
                this.dialogInstance1.height = '180px';
                this.dialogInstance1.minHeight = '180px';
                this.dialogInstance1.show();
            }
            else {
                this.dialogInstance1.hide();
                setTimeout(() => {
                    sessionStorage.removeItem('JellyfishTechMobileDatiIncarico');
                    ++this.childKey;
                    if (document.getElementById('defaultDialogDoc3'))
                        document.getElementById('defaultDialogDoc3').ej2_instances[0].destroy();    
                    const container = document.getElementById('contenutoDinamico2');
                    const root = createRoot(container); 
                    root.render(<SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>); 
                }, 200);
            }
        });
    }
    ProgrButtCreated3() {
        document.getElementById('btnInviaAppuntSede').ej2_instances[0].element.addEventListener('click', () => {
            if (CustomJS.isNullEmpty(document.getElementById('DataOraAppunt').ej2_instances[0].value))
                return;
            this.dialogInstance1.buttons = this.dialogButtons4;
            this.dialogInstance1.content = 'Una email di notifica dell\'appuntamento verrà inviata alla sede ACT. Confermare l\'invio?';
            this.dialogInstance1.width = '35%';
            this.dialogInstance1.height = '180px';
            this.dialogInstance1.minHeight = '180px';
            this.dialogInstance1.show();
        });
    }
    ProgrButtCreated4() {
        document.getElementById('btnInviaNotaSede').ej2_instances[0].element.addEventListener('click', () => {
            if (CustomJS.isNullEmpty(this.RTEObj.value?.trim()))
                return;
            this.dialogInstance1.buttons = this.dialogButtons5;
            this.dialogInstance1.content = 'Una Email contenente la nota relativa all\'incarico verrà inviata alla sede ACT. Confermare l\'invio?';
            this.dialogInstance1.width = '35%';
            this.dialogInstance1.height = '180px';
            this.dialogInstance1.minHeight = '180px';
            this.dialogInstance1.show();
        });
    }

    render() {
        const titoloPagina = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW' ? 'INSERIMENTO NUOVO INCARICO' : `DETTAGLIO INCARICO NUMERO ${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico}`;
        return (<React.Fragment>
            <BlockUi id='blockUIIncarico' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='35%'
                    ref={dialog => this.dialogInstance1 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='180px' minHeight='180px' isModal={true}
                    content=''>
                </DialogComponent>
                <div className='container-fluid'>
                    <div className='row'>&nbsp;</div>
                    <div className="row">
                        <div className="col-xs-11 col-sm-11 col-lg-10 col-md-10" style={{ marginTop: '5px' }}>
                            <span style={{ fontSize: '3.6vw', fontWeight: 'bolder', color: '#133B95' }}>{titoloPagina}</span>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2 mt-3">
                            <ProgressButtonComponent id='btnSalvaEsci' content="Salva ed esci" enableProgress duration='1000' spinSettings={{ position: 'Center' }} disabled={JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'INV'}
                                animationSettings={{ effect: 'SlideRight' }} created={this.ProgrButtCreated1.bind(this)} cssClass="e-fill e-success"></ProgressButtonComponent>
                            &nbsp;
                            <ProgressButtonComponent id='btnEsci' content="Esci" enableProgress duration='1000' style={{ marginTop: '5px' }} spinSettings={{ position: 'Center' }}
                                animationSettings={{ effect: 'SlideRight' }} created={this.ProgrButtCreated2.bind(this)} cssClass="e-outline e-danger"></ProgressButtonComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ToastContainer enableMultiContainer containerId='toastContainer2' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                            <TabComponent id='TabIncarico' cssClass='e-fill' headerPlacement='Top' width='100%' ref={tabComp => this.tabInstance = tabComp} selecting={this.tabSelecting.bind(this)}
                                animation={{ previous: { effect: 'FadeIn', duration: 500, easing: 'ease' }, next: { effect: 'FadeIn', duration: 500, easing: 'ease' } }}>
                                <TabItemsDirective>
                                    <TabItemDirective header={this.tabHeaderText[0]} content={this.tabContent0.bind(this)} width='100%' />
                                    <TabItemDirective header={this.tabHeaderText[1]} content={this.tabContent1.bind(this)} width='100%' />
                                </TabItemsDirective>
                            </TabComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        setTimeout(() => {
            this.formObject = new FormValidator('#formIncarico', this.formOptions);
            $("hamburger").unbind("click");
            //document.getElementById('hamburger').style.disabled = true;
            if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).Stato === 'DAINV')
                document.getElementById('divDataChiusura').style.display = 'none';
            if (JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico === 'NEW')
                this.tabInstance.hideTab(1, true);
            else
                delete this.formObject.rules.IdIncarico;
            if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).AppuntInviato)
                && JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).AppuntInviato === 0
                && !CustomJS.isNullEmpty(document.getElementById('DataOraAppunt').ej2_instances[0].value))
                document.getElementById('divAppuntInviato').innerHTML = '<span style="font-size: 1.2vw;font-weight:600;color:red;">Appuntamento non inviato in sede</span>';
            else if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).AppuntInviato)
                && JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).AppuntInviato === 1
                && !CustomJS.isNullEmpty(document.getElementById('DataOraAppunt').ej2_instances[0].value))
                document.getElementById('divAppuntInviato').innerHTML = '<span style="font-size: 1.2vw;font-weight:600;color:green;">Appuntamento inviato in sede</span>';
            else
                document.getElementById('divAppuntInviato').innerHTML = '';
            $('[id=NotaIncarico_toolbar_BackgroundColor_Target]').hide();
            $('[id=NotaIncarico_toolbar_FontColor_Target]').hide();
            caricaDati(this);
        }, 100);
    }
    componentWillUnmount() {
        this.RTEObj.destroy();
    }
}

function caricaDati(thisClone) {
    const GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).GuidIncarico;
    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTech/caricaListeIncarico/${GuidIncarico}/LOGMSG`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, retData => { 
        if (retData.response === 'KO') {
            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
            toast.warn(messaggio, {
                containerId: 'toastContainer2',
                onClose: () => toast.dismiss(),                    
                position: "right-center",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true, 
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            return;
        }               
        let GridData = [];
        retData.forEach(elm => {           
            GridData.push({ Guid: elm.GUID, DataOraInvioMessaggio: moment(elm.DATA_ORA_INVIO).format('DD/MM/YYYY HH:mm'), TestoMessaggio: elm.TESTO_MESSAGGIO });
        });
        thisClone.gridInstance.dataSource = GridData;        
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
        toast.warn(messaggio, {
            containerId: 'toastContainer2',
            onClose: () => toast.dismiss(),                    
            position: "right-center",
            autoClose: 15000,
            hideProgressBar: false,            
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true});        
    }, true, false);

    const QueryExec = `SELECT id_compagnia FROM [01001] WHERE id_interno_sinistro = '${JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico')).IdIncarico}'`;
    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd2, JSON.parse(sessionStorage.getItem('JellyfishTechMobileAuthenticationPayload')).token, 'GET', QueryExec, '', retData => {
        let objDettaglio = JSON.parse(sessionStorage.getItem('JellyfishTechMobileDatiIncarico'));
        objDettaglio.IdCompagnia = retData[0].id_compagnia;
        sessionStorage.setItem('JellyfishTechMobileDatiIncarico', JSON.stringify(objDettaglio));
    }, messaggio => {
        toast.warn(messaggio, {
            containerId: 'toastContainer2',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true
        });
    });
}